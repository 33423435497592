@import url('https://fonts.googleapis.com/css?family=Rubik');

.font-small-1 {
  font-size: 0.7rem;
  font-family: 'Rubik', sans-serif;
}
.font-small-2 {
  font-size: 0.8rem;
  font-family: 'Rubik', sans-serif;
}

.font-normal {
  font-size: 1rem;
  font-family: 'Rubik', sans-serif;
}

.font-medium-1 {
  font-size: 1.1rem;
  font-family: 'Rubik', sans-serif;
}
.font-medium-2 {
  font-size: 1.2rem;
  font-family: 'Rubik', sans-serif;
}
.font-medium-3 {
  font-size: 1.3rem;
  font-family: 'Rubik', sans-serif;
}
.font-medium-4 {
  font-size: 1.4rem;
  font-family: 'Rubik', sans-serif;
}
.font-medium-5 {
  font-size: 1.5rem;
  font-family: 'Rubik', sans-serif;
}
.font-medium-6 {
  font-size: 1.75rem;
  font-family: 'Rubik', sans-serif;
}

.font-large-1 {
  font-size: 2rem;
  font-family: 'Rubik', sans-serif;
}
.font-large-2 {
  font-size: 3rem;
  font-family: 'Rubik', sans-serif;
}
.font-large-3 {
  font-size: 4rem;
  font-family: 'Rubik', sans-serif;
}
.font-large-4 {
  font-size: 5rem;
  font-family: 'Rubik', sans-serif;
}
.font-large-5 {
  font-size: 6rem;
  font-family: 'Rubik', sans-serif;
}

.text-bold-300 {
font-weight: lighter;
font-family: 'Rubik', sans-serif;
}
.text-bold-500 {
  font-weight: medium;
  font-family: 'Rubik', sans-serif;
}
.text-bold-700 {
  font-weight: bold ;
  font-family: 'Rubik', sans-serif;
}

.card-biodata{
  border-radius: 5px;
  height: 100%;
  width: 100;
  overflow: hidden;
}