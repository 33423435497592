

/* 
hero
---- 
*/

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.heroBlock {
  background-position: 50% 100%;
  background-repeat:no-repeat;
  background-size: cover;
}

.heroBlock .container-fluid {
 display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}


.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heroBlock p {
  margin: 0 0 30px;
  color: white;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/*Print*/
.print-source {
  display: none;
  background-color: red;
}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
    text-align: center;
    background-color: transparent;
  }
  @page {
    size: landscape;
  }
}

.print-potrait {
  display: none;
  background-color: red;
}

body > .print-potrait {
  display: block;
}

@media print {
  .print-potrait {
    display: block;
    text-align: center;
    background-color: transparent;
  }
 
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe#webpack-dev-server-client-overlay{display:none!important}